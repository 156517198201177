import React from "react"
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Formik } from "formik"
import _ from "lodash"

import Routes from "@/routes"
import FormikBootstrap from "@/components/formik-bootstrap"
import { ComponentType, newComponent } from "@/onboarding/features/product/models/Component"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import {
  appendFieldListItem,
  removeFieldListItem,
  updateFieldListItem,
} from "@/onboarding/features/product/Slice"
import { canEdit, getField, getMediaItems } from "@/onboarding/features/product/selectors"
import SectionHolder from "@/onboarding/features/product/components/shared/SectionHolder"
import LoadingSpinner from "@/components/LoadingSpinner"
import { useProductSubmitting } from "@/onboarding/features/product/helpers"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import EditIcon from "@/onboarding/features/product/components/shared/EditIcon"
import EditModal from "@/onboarding/features/product/components/shared/EditModal"
import { useModal } from "@/helpers/useModal"
import ErrorBoundary from "@/components/ErrorBoundary"
import { isBlank } from "@/utils"

const Edit = ({ media }): JSX.Element => {
  const dispatch = useDispatch()
  const [isSubmitting, setSubmitting] = useProductSubmitting()

  return (
    <Formik
      initialValues={media.componentAttributes}
      onSubmit={(values) => {
        setSubmitting(true)
        dispatch(
          updateFieldListItem({
            fieldName: media.fieldName,
            index: media.componentIndex,
            value: values,
          })
        )
      }}
    >
      {({ handleSubmit, dirty }) => (
        <Form className="d-flex flex-column gap-4" onSubmit={handleSubmit}>
          <Row>
            <Col xs={6}>
              <FormikBootstrap.TextInput name="name" label="Name" />
            </Col>
            <Col xs={6}>
              <FormikBootstrap.Select name="promotional" label="Promotional Use">
                <option value="false">Not allowed</option>
                <option value="true">Allowed</option>
              </FormikBootstrap.Select>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormikBootstrap.TextAreaInput name="description" label="Description" />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormikBootstrap.FileInput name="imageSrc" label="File" />
            </Col>
          </Row>
          <Row>
            <Col>
              <b>Photo Specifications</b>
              <ul>
                <li>Minimum width: 1920px</li>
                <li>Minimum height: 1080px</li>
                <li>Orientation: Landscape</li>
                <li>Resolution: High (minimum 300 pixels per inch)</li>
                <li>Format: JPG (maximum quality)</li>
              </ul>
              <b>Video Specifications</b>
              <ul>
                <li>Resolution: 1920x1080</li>
                <li>Format: MP4</li>
                <li>
                  Web version: voice over and graphics limited to tour description. No mention of
                  third-party company/operator brand. We prefer a Youtube video as we would need
                  that format to be able to upload it.
                </li>
                <li>
                  Onboard version: No graphics on the bottom third of screen so we can insert our
                  own tour name, code, etc.
                </li>
                <li>
                  Note: Do not include/mention tour operator brand on either version if they&apos;re
                  different.
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex gap-4">
              <div>
                <Button type="submit" className="text-nowrap" disabled={!dirty || isSubmitting}>
                  <i className="fas fa-save me-2" />
                  Save
                </Button>
              </div>
              <div>
                <Button
                  className="text-nowrap"
                  variant="danger"
                  disabled={isSubmitting}
                  onClick={() => {
                    setSubmitting(true)
                    dispatch(
                      removeFieldListItem({
                        fieldName: media.fieldName,
                        index: media.componentIndex,
                      })
                    )
                  }}
                >
                  <i className="fas fa-trash me-2" />
                  Delete
                </Button>
              </div>
              {isSubmitting && <LoadingSpinner />}
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

const MediaTile = ({ media, editable = true }) => {
  const toggleEdit = useModal((props) => (
    <EditModal {...props} title="Media" Body={Edit} bodyProps={{ media }} />
  ))

  // TODO(product): We should render some sort of "missing" png here
  const mediaPath = isBlank(media.componentAttributes.imageSrc)
    ? null
    : Routes.file_holder_path({ id: media.componentAttributes.imageSrc })

  return (
    <div
      className="d-flex flex-column border sahdow-sm bg-body rounded p-1"
      style={{ width: "512px" }}
    >
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`media-tooltip-${media.name}`}>{media.description}</Tooltip>}
      >
        <video style={{ height: "288px" }} poster={mediaPath} muted autoPlay>
          <source src={mediaPath} type="video/mp4" />
        </video>
      </OverlayTrigger>
      <div className="d-flex justify-content-between gap-2">
        <div className="d-flex gap-2">
          <span>
            <b>
              <TextDisplay value={media.name} />
            </b>
          </span>
          {media.promotional && <span>(Promotional)</span>}
        </div>
        <div className="d-flex gap-2">
          <div>
            <a href={mediaPath} download className="cursor-pointer">
              <i className="fas fa-download" />
            </a>
          </div>
          {editable && (
            <div>
              <EditIcon onEdit={toggleEdit} />
            </div>
          )}
          {!_.isEmpty(media.issues) && (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id={`media-tooltip-${media.name}-issues`}>
                  {media.issueDescription}
                </Tooltip>
              }
            >
              <div>
                <span className="text-danger">
                  <i className="fas fa-exclamation-triangle" />
                </span>
              </div>
            </OverlayTrigger>
          )}
        </div>
      </div>
    </div>
  )
}

const MediaSection = (): JSX.Element => {
  const dispatch = useDispatch()
  const field = useSelector(getField(ReservedFields.TOUR_IMAGES))
  const editable = field.writeable && useSelector(canEdit)
  const mediaItems = useSelector(getMediaItems)
  const [isSubmitting, setSubmitting] = useProductSubmitting()
  const prompt = field.required
    ? "No media loaded. This is a required component."
    : "No media loaded for this tour"

  const addMedia = () => {
    setSubmitting(true)

    dispatch(
      appendFieldListItem({
        fieldName: ReservedFields.TOUR_IMAGES,
        value: newComponent({ kind: ComponentType.IMAGE, defaultRateCategories: [] }),
      })
    )
  }

  // TODO(tour-page): Figure out why this ErrorBoundary was added originally and remove if we're sure it's safe.
  return (
    <SectionHolder
      title="Tour Images and Video"
      showLockedIcon={!field.writeable}
      missingRequiredComponent={field.required && _.isEmpty(mediaItems)}
    >
      <div className="d-flex flex-column gap-2">
        {_.isEmpty(mediaItems) && <div>{prompt}</div>}
        <div className="d-flex flex-wrap gap-3">
          {mediaItems.map((item) => (
            <ErrorBoundary key={item.componentIndex}>
              <MediaTile media={item} editable={editable} />
            </ErrorBoundary>
          ))}
        </div>
        {editable && (
          <div className="d-flex gap-2">
            <Button size="sm" variant="outline-primary" onClick={addMedia} disabled={isSubmitting}>
              <i className="fas fa-plus me-2" />
              Add Media
            </Button>
            {isSubmitting && <LoadingSpinner />}
          </div>
        )}
      </div>
    </SectionHolder>
  )
}

export default MediaSection
