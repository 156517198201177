import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Button } from "react-bootstrap"

import { useSliceSelector, useSliceActions } from "@/helpers/SliceProvider"

import FormikFormChangeTracker from "@/components/Wizard/FormikFormChangeTracker"
import FormikFormValidityTracker from "@/components/Wizard/FormikFormValidityTracker"
import Questionnaire from "@/form_toolkit/components/Questionnaire"

import { getForm } from "../selectors"

const OperatorQuestions = (props): JSX.Element => {
  const dispatch = useDispatch()

  const { updateOperatorQuestionnaire } = useSliceActions()

  const { operatorQuestionnaire, submitting } = useSliceSelector(getForm)

  const [hasChanged, setHasChanged] = useState(false)

  const [valuesAfterChange, setValuesAfterChange] = useState(null)

  const formNavigationWithDisabling = props.formNavigation.map((button) =>
    React.cloneElement(button, {
      disabled: hasChanged || button.props.disabled,
    })
  )

  return (
    <div>
      <h2 className="h5">{props.title}</h2>
      <hr />
      <Questionnaire
        questionnaire={operatorQuestionnaire}
        onSubmit={(values) =>
          dispatch(updateOperatorQuestionnaire({ values: { values } })).then(() => {
            setValuesAfterChange(values)
            setHasChanged(false)
          })
        }
        readOnly={props.context.readOnly}
      >
        {hasChanged && (
          <p className="text-warning">
            Form has unsaved changes. Click Save below before continuing.
          </p>
        )}
        <div className="d-flex gap-2">
          <FormikFormChangeTracker
            setHasChanged={setHasChanged}
            currentValues={valuesAfterChange}
          />
          <FormikFormValidityTracker setValidity={props.setValidity} />

          {formNavigationWithDisabling}

          {!props.context.readOnly && (
            <Button type="submit" variant="success" disabled={submitting}>
              Save
            </Button>
          )}
        </div>
      </Questionnaire>
    </div>
  )
}

export default OperatorQuestions
