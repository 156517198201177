import React, { FC } from "react"

import { useSelector } from "react-redux"
import SectionHolder from "./shared/SectionHolder"
import Content from "./additional_details/Content"
import Edit from "./additional_details/Edit"
import { getSectionFieldNames, countIncompleteRequiredFields } from "../selectors"

const AdditionalDetails: FC = () => {
  const fieldNames = useSelector(getSectionFieldNames.additionalDetails)
  const incompleteFieldCount = useSelector(countIncompleteRequiredFields(...fieldNames))

  return (
    <SectionHolder
      title="Additional Details and Restrictions"
      editComponent={Edit}
      editMethod="inline"
      incompleteRequiredFieldCount={incompleteFieldCount}
    >
      <Content />
    </SectionHolder>
  )
}

export default AdditionalDetails
