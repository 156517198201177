import React, { FC } from "react"
import { Dropdown, DropdownButton } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"

import { useModal } from "@/helpers/useModal"
import {
  getActiveBid,
  getProduct,
  getFeatures,
  getProductFilters,
} from "@/onboarding/features/product/selectors"
import {
  acceptBid,
  activateProduct,
  closeProductForBidding,
  openProductForBidding,
  pendBid,
  removeBid,
  resetProduct,
  submitBid,
} from "@/onboarding/features/product/Slice"
import routes from "@/routes"
import ButtonLink from "@/components/ButtonLink"

import ProposeTourModal from "./ProposeTourModal"
import EditMainDetailsModal from "./EditMainDetailsModal"
import FieldSettingsModal from "./FieldSettingsModal"
import AddContractPeriodModal from "./AddContractPeriodModal"
import ImportComponentModal from "./ImportComponentModal"

const ToolsTitle: FC = () => (
  <>
    <i className="fas fa-cog me-2" />
    Tools
  </>
)

// TODO Move the bid related stuff into the app as well, so that this can function completely using
//  the feature checks.
const ToolsDropdown: FC = () => {
  const dispatch = useDispatch()

  const {
    id,
    season: { id: seasonId },
  } = useSelector(getProduct)
  const { contractPeriodId } = useSelector(getProductFilters)
  const activeBid = useSelector(getActiveBid)
  const { toolsDropdown: features } = useSelector(getFeatures)

  const toggleProposeModal = useModal((props) => <ProposeTourModal {...props} />)
  const toggleEditMainDetailsModal = useModal((props) => <EditMainDetailsModal {...props} />)
  const toggleFieldSettingsModal = useModal((props) => <FieldSettingsModal {...props} />)
  const toggleAddContractPeriodModal = useModal((props) => <AddContractPeriodModal {...props} />)
  const toggleImportComponentModal = useModal((props) => <ImportComponentModal {...props} />)

  if (!_.some(features, (enabled) => enabled)) {
    return null
  }

  return (
    <div>
      <DropdownButton title={<ToolsTitle />}>
        {features.submitBid && activeBid && (
          <Dropdown.Item onClick={() => dispatch(submitBid({ bidId: activeBid.id }))}>
            Submit Bid
          </Dropdown.Item>
        )}
        {features.submitProposal && (
          <Dropdown.Item onClick={toggleProposeModal}>Propose Tour</Dropdown.Item>
        )}
        {features.openBidding && (
          <Dropdown.Item onClick={() => dispatch(openProductForBidding({}))}>
            Open Bidding
          </Dropdown.Item>
        )}
        {features.closeBidding && (
          <Dropdown.Item onClick={() => dispatch(closeProductForBidding({}))}>
            Close Bidding
          </Dropdown.Item>
        )}
        {features.editMainDetails && (
          <Dropdown.Item onClick={toggleEditMainDetailsModal}>Edit Main Details</Dropdown.Item>
        )}
        {features.modifyFieldSettings && (
          <Dropdown.Item onClick={toggleFieldSettingsModal}>Edit Field Settings</Dropdown.Item>
        )}
        {features.priceAnalysis && (
          <Dropdown.Item
            href={routes.onboarding_price_analysis_path({ season_id: seasonId, product_id: id })}
          >
            Cost Analysis
          </Dropdown.Item>
        )}
        {features.inventoryAllocation && (
          <Dropdown.Item
            href={routes.onboarding_inventory_allocation_path({
              season_id: seasonId,
              product_id: id,
            })}
          >
            Inventory Allocation
          </Dropdown.Item>
        )}
        {features.activate && (
          <Dropdown.Item onClick={() => dispatch(activateProduct({}))}>Activate Tour</Dropdown.Item>
        )}
        {features.acceptBid && activeBid && (
          <Dropdown.Item onClick={() => dispatch(acceptBid({ bidId: activeBid.id }))}>
            Accept Bid
          </Dropdown.Item>
        )}
        {features.rejectBid && activeBid && (
          <ButtonLink
            label="Reject Bid"
            className="dropdown-item"
            button={false}
            remote
            params={{
              refresh_page: true,
              season_id: seasonId,
              product_ids: [id],
              operator_id: activeBid.operator.id,
            }}
            href={routes.new_onboarding_bidding_deny_appointment_path()}
          />
        )}
        {features.rerequestBid && activeBid && (
          <Dropdown.Item onClick={() => dispatch(pendBid({ bidId: activeBid.id }))}>
            Ready Bid for Resubmission
          </Dropdown.Item>
        )}
        {features.removeBid && activeBid && (
          <Dropdown.Item onClick={() => dispatch(removeBid({ bidId: activeBid.id }))}>
            Remove Bid
          </Dropdown.Item>
        )}
        {features.reset && (
          <Dropdown.Item onClick={() => dispatch(resetProduct({}))}>Reset Tour</Dropdown.Item>
        )}
        {features.addContractPeriod && (
          <Dropdown.Item onClick={toggleAddContractPeriodModal}>Adjust Rates</Dropdown.Item>
        )}
        {features.requestContractPeriodDetailsConfirmation && contractPeriodId && (
          <ButtonLink
            label="Request Tour Details Confirmation"
            className="dropdown-item"
            button={false}
            remote
            params={{
              refresh_page: true,
              season_id: seasonId,
              contract_period_id: contractPeriodId,
            }}
            href={routes.new_onboarding_bidding_request_contract_period_details_confirmation_path()}
          />
        )}
        {features.importComponent && (
          <Dropdown.Item onClick={toggleImportComponentModal}>Import Component</Dropdown.Item>
        )}
      </DropdownButton>
    </div>
  )
}

export default ToolsDropdown
