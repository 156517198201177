import React, { FC } from "react"

import { useSelector } from "react-redux"
import SectionHolder from "./shared/SectionHolder"
import Content from "./web_content/Content"
import Edit from "./web_content/Edit"
import { getSectionFieldNames, countIncompleteRequiredFields } from "../selectors"

const WebContent: FC = () => {
  const fieldNames = useSelector(getSectionFieldNames.description)
  const incompleteFieldCount = useSelector(countIncompleteRequiredFields(...fieldNames))

  return (
    <SectionHolder
      title="Web Content"
      editComponent={Edit}
      editMethod="inline"
      incompleteRequiredFieldCount={incompleteFieldCount}
    >
      <Content />
    </SectionHolder>
  )
}
export default WebContent
