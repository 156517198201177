import React, { FC, useState } from "react"
import { Button, Form, Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { FieldArray, Formik } from "formik"
import _ from "lodash"

import FormikBootstrap from "@/components/formik-bootstrap"
import {
  getStops,
  getSectionFieldNames,
  getDefaultRateCategories,
  getField,
} from "@/onboarding/features/product/selectors"
import {
  appendFieldListItem,
  removeFieldListItem,
  updateFieldListItem,
} from "@/onboarding/features/product/Slice"
import { newComponent, ComponentType } from "@/onboarding/features/product/models/Component"
import { ReservedFields, ReferencedValueTypes } from "@/onboarding/features/product/models/Product"
import ReferencedValueSelect from "@/onboarding/features/product/components/shared/ReferencedValueSelect"
import FieldInput from "@/onboarding/features/product/components/shared/FieldInput"
import RateInputs from "@/onboarding/features/product/components/shared/RateInputs"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import { BlueLock } from "@/components/Icons"

const ComponentEdit = ({ index }): JSX.Element => (
  <div className="d-flex flex-column gap-3">
    <FormikBootstrap.TextInput name={`components.${index}.name`} label="Name" />
    <FormikBootstrap.TextInput name={`components.${index}.description`} label="Description" />
    <RateInputs name={`components.${index}.ratesheet`} />
  </div>
)

const ComponentList = ({ values, arrayHelpers }): JSX.Element => {
  const [editingComponent, setEditingComponent] = useState(null)
  const defaultRateCategories = useSelector(getDefaultRateCategories)

  return (
    <div>
      <div className="d-flex justify-content-between align-content-center w-100">
        <h2 className="h5 mb-0">Components and Rates</h2>
        <span
          className="hover-underline cursor-pointer"
          onClick={() =>
            arrayHelpers.push(newComponent({ defaultRateCategories, kind: ComponentType.SIMPLE }))
          }
        >
          Add Component
        </span>
      </div>
      <Table responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {_.isEmpty(values.components) && (
            <tr>
              <td colSpan={2}>No components loaded</td>
            </tr>
          )}
          {values.components.map((component, index) => (
            <React.Fragment key={index}>
              <tr>
                <td>
                  <TextDisplay value={component.name} />
                </td>
                <td>
                  <div className="d-flex justify-content-end gap-3">
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        setEditingComponent(index === editingComponent ? null : index)
                      }}
                    >
                      <i className="fas fa-edit" />
                    </span>
                    <span
                      className="cursor-pointer text-danger"
                      onClick={() => arrayHelpers.remove(index)}
                    >
                      <i className="fas fa-trash" />
                    </span>
                  </div>
                </td>
              </tr>
              {index === editingComponent && (
                <tr>
                  <td colSpan={2}>
                    <ComponentEdit index={index} />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

const StopEditForm = ({ stop, index, onCancel }): JSX.Element => {
  const dispatch = useDispatch()

  return (
    <Formik
      enableReinitialize
      initialValues={stop}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true)
        dispatch(updateFieldListItem({ fieldName: stop.fieldName, index, value: values }))
        setSubmitting(false)
        onCancel()
      }}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
          <FormikBootstrap.TextInput name="time" label="Time" />
          <FormikBootstrap.NumberInput name="duration" label="Duration (Minutes)" />
          <ReferencedValueSelect
            name="stopType"
            type={ReferencedValueTypes.STOP_TYPE}
            label="Type"
          />
          <ReferencedValueSelect
            name="transferType"
            type={ReferencedValueTypes.VEHICLE_TYPE}
            label="Transfer Type"
          />
          <FormikBootstrap.TextInput name="name" label="Name" />
          <FormikBootstrap.TextInput name="description" label="Description" />
          <FormikBootstrap.NumberInput name="steps" label="Steps" />
          <FormikBootstrap.TextInput name="terrain" label="Terrain" />
          <FormikBootstrap.Switch name="bathroomsAvailable" label="Bathrooms Available?" />
          <FieldArray
            name="components"
            render={(arrayHelpers) => <ComponentList values={values} arrayHelpers={arrayHelpers} />}
          />
          <div className="d-flex gap-3">
            <Button type="submit" disabled={isSubmitting}>
              Submit
            </Button>
            <Button variant="danger" onClick={onCancel} disabled={isSubmitting}>
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const LocationEdit: FC = () => {
  const fieldNames = useSelector(getSectionFieldNames.tourLocation)

  return (
    <div className="d-flex flex-column gap-3">
      {fieldNames.map((fieldName) => (
        <FieldInput key={fieldName} name={fieldName} />
      ))}
    </div>
  )
}

const Edit: FC = () => {
  const [editingStop, setEditingStop] = useState(null)
  const dispatch = useDispatch()
  const stops = useSelector(getStops)
  const defaultRateCategories = useSelector(getDefaultRateCategories)
  const field = useSelector(getField(ReservedFields.TOUR_STOPS))
  const addStop = () => {
    dispatch(
      appendFieldListItem({
        fieldName: ReservedFields.TOUR_STOPS,
        value: newComponent({ defaultRateCategories, kind: ComponentType.STOP }),
      })
    )
  }

  const cancelEdit = () => setEditingStop(null)

  if (editingStop !== null) {
    return <StopEditForm stop={stops[editingStop]} index={editingStop} onCancel={cancelEdit} />
  }

  return (
    <>
      <LocationEdit />
      <div className="d-flex align-items-center">
        {!field.writeable && (
          <BlueLock
            className="me-2 mt-3 cursor-pointer"
            data-toggle="tooltip"
            data-placement="top"
            title="This value is fixed and cannot be edited"
          />
        )}
        <h2 className="mt-4 h5">Tour Itinerary</h2>
      </div>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {stops.length === 0 && (
            <tr>
              <td colSpan={2}>
                No tour stops loaded
                {field.required && (
                  <span
                    data-toggle="tooltip"
                    data-placement="top"
                    title="This is an incomplete required component"
                    className="ms-2"
                    style={{ color: "red" }}
                  >
                    *
                  </span>
                )}
              </td>
            </tr>
          )}
          {stops.map((stop, index) => (
            <tr key={`${stop.name}-${index}`}>
              <td>
                <TextDisplay value={stop.name} />
              </td>
              <td>
                {field.writeable && (
                  <div className="d-flex justify-content-end gap-3">
                    <span className="cursor-pointer" onClick={() => setEditingStop(index)}>
                      <i className="fas fa-edit" />
                    </span>

                    <span
                      className="cursor-pointer text-danger"
                      onClick={() =>
                        dispatch(
                          removeFieldListItem({
                            fieldName: ReservedFields.TOUR_STOPS,
                            index,
                          })
                        )
                      }
                    >
                      <i className="fas fa-trash" />
                    </span>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {field.writeable && (
        <Button size="sm" variant="outline-primary" onClick={addStop}>
          <i className="fas fa-plus me-2" />
          Add Stop
        </Button>
      )}
    </>
  )
}

export default Edit
